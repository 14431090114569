import React from 'react';
import { Money } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { usePrice } from '../../../../../hooks';
import { classes as commonClasses } from '../../../../../styles/common.st.css';
import { st, classes } from './Price.st.css';

interface Props {
  price?: Money;
  highlighted: boolean;
}

export const Price: React.FC<Props> = ({ price: money, highlighted }) => {
  const { isMobile } = useEnvironment();
  const { price, currency } = usePrice({
    value: money?.value,
    currency: money?.currency,
    hideTrailingFractionZeroes: true,
  });

  return (
    <div data-hook={VIEWER_HOOKS.PLAN_PRICE} className={st(classes.wrapper, { highlighted, mobile: isMobile })}>
      <span aria-hidden="true" data-hook={VIEWER_HOOKS.PRICE_CURRENCY} className={classes.currency}>
        {currency}
      </span>
      <span aria-hidden="true" data-hook={VIEWER_HOOKS.PRICE_AMOUNT} className={classes.price}>
        {price}
      </span>
      <span data-hook={VIEWER_HOOKS.PLAN_PRICE_SCREEN_READER} className={commonClasses.srOnly}>
        {price + currency}
      </span>
    </div>
  );
};
